import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

const MySwal = withReactContent(Swal);

const WebsiteTour = ({ userId }) => {
  const [showTour, setShowTour] = useState(false);

  useEffect(() => {
    const checkTourStatus = async () => {
      if (userId) {
        try {
          const response = await axios.get(`http://localhost:5052/check_tour_status?user_id=${userId}`);
          setShowTour(!response.data.never_see_again);
        } catch (error) {
          console.error('Error checking tour status:', error);
          setShowTour(true);
        }
      }
    };
    checkTourStatus();
  }, [userId]);

  const updateTourStatus = async (neverSeeAgain) => {
    try {
      await axios.post('http://localhost:5052/update_tour_status', {
        user_id: userId,
        never_see_again: neverSeeAgain
      });
    } catch (error) {
      console.error('Error updating tour status:', error);
    }
  };

  const runTour = async () => {
    const steps = [
      {
        title: 'Welcome to CrossValidation.ai',
        html: `
          <div class="tour-step">
            <h3>Discover the Catholic AI Efficient Investing Index</h3>
            <p>Embark on a journey of faith-aligned, AI-driven investment strategies.</p>
          </div>
        `,
        confirmButtonText: 'Next'
      },
      {
        title: 'Our AI-Powered Strategy',
        html: `
          <div class="tour-step">
            <h3>Cutting-Edge AI Selection Process</h3>
            <div class="tour-list">
              <div class="tour-list-item">
                <span class="tour-bullet">•</span>
                <span class="tour-text">Industry-relative metrics analysis</span>
              </div>
              <div class="tour-list-item">
                <span class="tour-bullet">•</span>
                <span class="tour-text">Efficiency ratio optimization</span>
              </div>
              <div class="tour-list-item">
                <span class="tour-bullet">•</span>
                <span class="tour-text">Catholic values alignment assessment</span>
              </div>
            </div>
            <p>We leverage advanced algorithms to identify top-performing, ethically-aligned AI companies across sectors.</p>
          </div>
        `,
        confirmButtonText: 'Next'
      },
      {
        title: 'Interactive Company Analysis',
        html: `
          <div class="tour-step">
            <h3>Dive Deep into AI-Driven Insights</h3>
            <p>Utilize our chat interface to explore detailed metrics and rankings. Create your tailored portfolio with our Investing Agent.</p>
          </div>
        `,
        confirmButtonText: 'Next'
      },
      {
        title: 'Personalized Portfolio Creation',
        html: `
          <div class="tour-step">
            <h3>AI-Optimized Investment Strategy</h3>
            <p>Answer five key questions to shape your unique portfolio. Seamlessly connect your Schwab account for automated, AI-guided trading.</p>
          </div>
        `,
        confirmButtonText: 'Next'
      },
      {
        title: 'Intelligent Portfolio Management',
        html: `
          <div class="tour-step">
            <h3>AI-Assisted Investment Oversight</h3>
            <p>Leverage our AI agent for real-time updates, performance analytics, and portfolio adjustments. Experience the power of AI-driven rebalancing.</p>
          </div>
        `,
        confirmButtonText: 'Next'
      },
      {
        title: 'Accessible AI Investing',
        html: `
          <div class="tour-step">
            <h3>Start Your AI Investing Journey Today</h3>
            <p>Enjoy premium AI-powered investing for just $20/month on accounts under $25,000.</p>
            <p>Begin with as little as $100 and watch your faith-aligned, AI-optimized portfolio grow.</p>
          </div>
        `,
        confirmButtonText: 'Get Started'
      }
    ];

    for (let i = 0; i < steps.length; i++) {
      const result = await MySwal.fire({
        ...steps[i],
        showCancelButton: true,
        cancelButtonText: i === 0 ? 'Never show again' : 'Close tour',
        customClass: {
          container: 'tour-container',
          popup: 'tour-popup',
          header: 'tour-header',
          title: 'tour-title',
          closeButton: 'tour-close-button',
          content: 'tour-content',
          htmlContainer: 'tour-html-container',
          confirmButton: 'tour-confirm-button',
          cancelButton: 'tour-cancel-button'
        }
      });

      if (result.dismiss === Swal.DismissReason.cancel) {
        if (i === 0) {
          await updateTourStatus(true);
        }
        break;
      }
    }
    setShowTour(false);
  };

  useEffect(() => {
    if (showTour) {
      runTour();
    }
  }, [showTour]);

  return null;
};

export default WebsiteTour;
