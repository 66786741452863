import * as yup from "yup";

export const signUpSchema = yup.object({
  email: yup
    .string("Email should be string")
    .required("Email is required")
    .email("Not a valid email"),
  password: yup
    .string("Password should be string")
    .required("Password is required")
    .min(5, "Password should be  atleast 5 characters")
    .max(20, "password should be less then 20 characters"),
  cPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const logInSchema = yup.object({
  email: yup
    .string("Email should be string")
    .required("Email is required")
    .email("Not a valid email"),
  password: yup
    .string("Password should be string")
    .required("Password is required")
    .min(5, "Password should be  atleast 5 characters")
    .max(20, "password should be less then 20 characters")
})
