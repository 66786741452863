import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { signUpSchema } from "../../schemas/userSchemas";
import { signup, clearSignUpErrors } from "../../store/actions/userActions";
import Swal from "sweetalert2";
import "./SignUp.css";
import Sms from "./images/sms.png";
import Eye from "./images/eye.png";
import Phone from "./images/sms.png";
import BtnLoader from "../../components/BtnLoader/BtnLoader";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";


const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const subscriptionType = "realtime-trading";


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, isAuthenticated, error, userId } = useSelector(
    (state) => state.user
  );


  const handleSignUp = async (values) => {
    try {
      const data = {
        email: values.email,
        phone: values.phone,
        password: values.password,
        subscriptionType: subscriptionType,
        watchlistCompanies: subscriptionType === "research" ? values.watchlistCompanies : null,
        watchlistSector: subscriptionType === "research" ? values.watchlistSector : null,
      };
      const userId = await dispatch(signup(data));
      
      // Show brief success message
      Swal.fire({
        icon: 'success',
        title: 'Signup Successful!',
        text: 'Redirecting you to the investment page...',
        timer: 2000,
        showConfirmButton: false
      }).then(() => {
        // Navigate to the /invest page after the Swal modal closes
        navigate("/invest", { state: { newUser: true, userId: userId } });
      });

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error}`,
      });
    }
  };





  const handleContinueAsGuest = async () => {
    const currentDateTime = new Date().toISOString().replace(/[^0-9]/g, "");
    const guestEmail = `guest_${currentDateTime}@guest.com`;
    const guestPassword = "aaaaa";

    await dispatch(signup({ email: guestEmail, password: guestPassword }));
  };

  const { handleChange, handleBlur, values, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        email: "",
        phone: "", // Add this line
        password: "",
        cPassword: "",
        watchlistCompanies: "",
        watchlistSector: "",
      },
      onSubmit: handleSignUp,
      validationSchema: signUpSchema,
      validateOnChange: false,
      validateOnBlur: true,
    });

  useEffect(() => {
    if (isAuthenticated && userId) {
      navigate("/chat");
    }
  }, [isAuthenticated, userId, navigate]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error}`,
      });
      dispatch(clearSignUpErrors());
    }
  }, [dispatch, error]);

  return (
    <div className="signup-container">
      <Header />
      <div className="signup-content">
        <Navbar />
        <div className="signup-main">
          <div className="signup-scroll-container">
            <h1 className="signup-title"></h1>
            <div className="plan-info">
              <h2>Register Your Account</h2>
              <p>
                Choose your sector allocation, investment amount, and number of stocks to create your optimal portfolio!
              </p>
              <p>
                If deciding to connect your schwab brokerage account the AI agent will continuously monitor portfolio, provide updates, and rebalance your portfolio for a 1% annual fee on assets under management (charged as 0.083% monthly). There is no minimum investment amount.  

              </p>
              <p>
                By registering you confirm that you have read and agree to our{' '}
                <a 
                  href="https://docs.google.com/document/d/1lbq3nwlzCUtoYLNQM94qaiYvVOD1EXS_mNYbp3LQN0o/edit?usp=sharing" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  terms and conditions
                </a>
                .
              </p>
            </div>
            <form className="signup-form" onSubmit={handleSubmit}>
              <div className="label-input">
                <label htmlFor="user-email">
                  Email <span className="required-field">*</span>
                </label>
                <div className={`icon-input ${errors.email ? 'error-border' : 'simple-border'} `}>
                  <img src={Sms} alt="envelope" />
                  <input
                    type="email"
                    name="email"
                    id="user-email"
                    placeholder="Enter Your Email Address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.email ? <span className="error-msg">{errors.email}</span> : <span className="error-msg"></span>}
              </div>
              <div className="label-input">
                <label htmlFor="user-phone">
                  Phone <span className="required-field">*</span>
                </label>
                <div className={`icon-input ${errors.phone ? 'error-border' : 'simple-border'} `}>
                  <img src={Phone} alt="phone" />
                  <input
                    type="tel"
                    name="phone"
                    id="user-phone"
                    placeholder="Enter Your Phone Number"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.phone ? <span className="error-msg">{errors.phone}</span> : <span className="error-msg"></span>}
              </div>
              <div className="label-input">
                <label htmlFor="user-password">
                  Password <span className="required-field">*</span>
                </label>
                <div className={`icon-input ${touched.cPassword && errors.cPassword ? 'error-border' : 'simple-border'} `} onClick={() => setShowPassword(!showPassword)}>
                  <img src={Eye} alt="Eye" />
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="user-password"
                    placeholder="Enter Your Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                {touched.password && errors.password && <span className="error-msg">{errors.password}</span>}
              </div>
              <div className="label-input">
                <label htmlFor="cnfrm-pass">
                  Confirm Password <span className="required-field">*</span>
                </label>
                <div className={`icon-input ${touched.cPassword && errors.cPassword ? 'error-border' : 'simple-border'} `} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  <img src={Eye} alt="Eye" />
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="cPassword"
                    id="cnfrm-pass"
                    placeholder="Re-enter your password"
                    value={values.cPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                {errors.cPassword && touched.cPassword && <span className="error-msg">{errors.cPassword}</span>}
              </div>
              {subscriptionType === "research" && (
                <>
                  <div className="label-input">
                    <label htmlFor="watchlist-companies">
                      Add 3 companies to your watchlist (comma-separated)
                    </label>
                    <input
                      type="text"
                      name="watchlistCompanies"
                      id="watchlist-companies"
                      placeholder="e.g., Intuit, Tesla, Goldman Sachs"
                      value={values.watchlistCompanies}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="watchlist-input"
                    />
                  </div>
                  <div className="label-input">
                    <label htmlFor="watchlist-sector">
                      Add 1 sector for your watchlist
                    </label>
                    <input
                      type="text"
                      name="watchlistSector"
                      id="watchlist-sector"
                      placeholder="e.g., Technology"
                      value={values.watchlistSector}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="watchlist-input"
                    />
                  </div>
                </>
              )}
              <button type="submit" className="signup-submit-btn">
                {loading ? <BtnLoader /> : "Sign Up"}
              </button>
              <div className="continue-as-guest" onClick={handleContinueAsGuest}>
                {/* Content for guest option if needed */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
