export const FETCH_ONBOARDING_STATUS_REQUEST = 'FETCH_ONBOARDING_STATUS_REQUEST';
export const FETCH_ONBOARDING_STATUS_SUCCESS = 'FETCH_ONBOARDING_STATUS_SUCCESS';
export const FETCH_ONBOARDING_STATUS_FAIL = 'FETCH_ONBOARDING_STATUS_FAIL';
export const SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';
export const COMPLETE_ONBOARDING = 'COMPLETE_ONBOARDING';
export const UPDATE_ONBOARDING_RESPONSE = 'UPDATE_ONBOARDING_RESPONSE';
export const ONBOARDING_ERROR = 'ONBOARDING_ERROR';  // Add this line
export const SET_ONBOARDING_STATUS = 'SET_ONBOARDING_STATUS';  // Add this line
export const UPDATE_ONBOARDING_STATUS = 'UPDATE_ONBOARDING_STATUS';  // Add this line

