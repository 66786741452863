import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { logInSchema } from "../../schemas/userSchemas";
import { signIn, clearSignUpErrors } from "../../store/actions/userActions";
import Swal from "sweetalert2";
import "./SignIn.css";
import Sms from "./images/sms.png";
import Eye from "./images/eye.png";
import BtnLoader from "../../components/BtnLoader/BtnLoader";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated, error, loading } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogIn = async () => {
    try {
      const data = {
        email: values.email,
        password: values.password,
      };
      await dispatch(signIn(data));
      // The navigation will be handled by the useEffect below
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error}`,
      });
    }
  };

  const { handleChange, handleBlur, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: handleLogIn,
    validationSchema: logInSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/chat");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error}`,
      });
      dispatch(clearSignUpErrors());
    }
  }, [dispatch, error, navigate]);

  return (
    <div className="signin-container">
      <Header />
      <div className="signin-content">
        <Navbar />
        <div className="signin-main">
          <h1 className="signin-title">Sign In</h1>
          <form action="" className="signin-form" onSubmit={handleSubmit}>
            <div className="label-input">
              <label htmlFor="user-email">
                Email <span className="required-field">*</span>
              </label>
              <div className={`icon-input ${errors.email ? 'error-border' : 'simple-border'}  `}>
                <img src={Sms} alt="envelope" />
                <input
                  type="email"
                  name="email"
                  id="user-email"
                  placeholder="Enter Your Email Address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.email ? <span className="error-msg">{errors.email}</span> : <span className="error-msg"></span>}
            </div>
            <div className="label-input">
              <label htmlFor="user-password">
                Password <span className="required-field">*</span>
              </label>
              <div className={`icon-input ${touched.password && errors.password ? 'error-border' : 'simple-border'} `}>
                <img
                  src={Eye}
                  alt="Eye"
                  onClick={() => setShowPassword(!showPassword)}
                />
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="user-password"
                  placeholder="Enter Your Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
              {touched.password && errors.password && <span className="error-msg">{errors.password}</span>}
            </div>
            <button type="submit" className="signin-submit-btn">
              {loading ? <BtnLoader /> : "Sign In"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;