import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Chat from './pages/Chat/Chat';
import Invest from './pages/Invest/Invest';
import SignUp from './pages/SignUp/SignUp';
import SignIn from './pages/SignIn/SignIn';
import About from './pages/About/About';
import Methodology from './pages/Methodology/Methodology';
import Authenticate from './pages/Authenticate/Authenticate';
import './App.css';
import ObjectivesChat from './pages/ObjectivesChat/ObjectivesChat';
import Portfolio from './pages/Portfolio/Portfolio';
import Trade from './pages/Trade/Trade';
import BrokerageAccount from './pages/BrokerageAccount/BrokerageAccount';
import { setUserId } from './store/actions/userActions';
import SchwabCallback from './components/SchwabCallback';

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      dispatch(setUserId(userId));
    }
  }, [dispatch]);

  return (
    <Router>
      <div className="app">
        <Navbar />
        <Routes>
          <Route path="/" element={<Chat />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/invest" element={<Invest />} />
          <Route path="/objectives-chat" element={isAuthenticated ? <ObjectivesChat /> : <SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/about" element={<About />} />
          <Route path="/methodology" element={<Methodology />} />
          <Route path="/authenticate" element={<Authenticate />} />
          <Route path="/portfolio" element={isAuthenticated ? <Portfolio /> : <SignIn />} />
          <Route path="/trade" element={isAuthenticated ? <Trade /> : <SignIn />} />
          <Route path="/brokerage-account" element={isAuthenticated ? <BrokerageAccount /> : <SignIn />} />
          <Route path="/schwab-callback" element={<SchwabCallback />} />
          <Route path="/callbacks" element={<SchwabCallback />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
