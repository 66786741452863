// Schwab Authentication
export const SCHWAB_AUTH_REQUEST = 'SCHWAB_AUTH_REQUEST';
export const SCHWAB_AUTH_SUCCESS = 'SCHWAB_AUTH_SUCCESS';
export const SCHWAB_AUTH_FAIL = 'SCHWAB_AUTH_FAIL';

// Schwab Authentication Completion
export const SCHWAB_AUTH_COMPLETE_REQUEST = 'SCHWAB_AUTH_COMPLETE_REQUEST';
export const SCHWAB_AUTH_COMPLETE_SUCCESS = 'SCHWAB_AUTH_COMPLETE_SUCCESS';
export const SCHWAB_AUTH_COMPLETE_FAIL = 'SCHWAB_AUTH_COMPLETE_FAIL';

// Schwab Logout
export const SCHWAB_AUTH_LOGOUT = 'SCHWAB_AUTH_LOGOUT';

// Set Authentication Status
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';

// Schwab Token Refresh
export const SCHWAB_TOKEN_REFRESH_REQUEST = 'SCHWAB_TOKEN_REFRESH_REQUEST';
export const SCHWAB_TOKEN_REFRESH_SUCCESS = 'SCHWAB_TOKEN_REFRESH_SUCCESS';
export const SCHWAB_TOKEN_REFRESH_FAIL = 'SCHWAB_TOKEN_REFRESH_FAIL';

// User Login
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

// User Logout
export const USER_LOGOUT = 'USER_LOGOUT';

// User Registration
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

// User Details
export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

// User Update Profile
export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';